/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



$('.slider-blok').slick({
  nextArrow: '<span class="slick-arrow arrow-right btn btn-primary btn-icon"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="slick-arrow arrow-left btn btn-primary btn-icon"><i class="fas fa-angle-left"></i></span>',
  dots: true
});

$('.slider-hero').slick({
  nextArrow: '<span class="slick-arrow arrow-right btn btn-primary btn-icon"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="slick-arrow arrow-left btn btn-primary btn-icon"><i class="fas fa-angle-left"></i></span>',
  autoplay: true,
  autoplaySpeed: 6000,
  arrows: false,
});

$('.slider-diensten').slick({
  nextArrow: '<span class="slick-arrow arrow-right btn btn-primary btn-icon"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="slick-arrow arrow-left btn btn-primary btn-icon"><i class="fas fa-angle-left"></i></span>',
  arrows: false,
  autoplay: true,
  autoplaySpeed: 6000,
  swipeToSlide: true,
  centerMode: true,
  variableWidth: true,
  initialSlide: 3,
});

$('.menu-toggle').click(function(){
  $('.hamburger', this).toggleClass('is-active');
  $('.menu-toggle-content').slideToggle(300);
});

        headerHeight = $(".banner").outerHeight();
        function smoothScrollHash(hash) {
          if ($(hash).length) {
        $('html,body').animate({
          scrollTop: $(hash).offset().top - headerHeight
        }, 500);
        return false;
          }
        }

        $('a[href*="/#"],a[href*="#"]').on('click',function(e){
          //e.preventDefault();
          if($(this).attr('data-toggle')){
            return;
          }
          if ($(window).width() < 992) {
            $('.menu-toggle .hamburger').removeClass('is-active');
            $('.menu-toggle-content').slideUp();
          }
          var hash = $(this).attr('href');
          hash = hash.split('#');
          if(hash){
            smoothScrollHash('#'+hash[1]);
          }
        });

        if (window.location.hash) {
           smoothScrollHash(window.location.hash);
        }

        $('a.magnific').magnificPopup({
          ajax: {
            settings: null, // Ajax settings object that will extend default one - http://api.jquery.com/jQuery.ajax/#jQuery-ajax-settings
            // For example:
            // settings: {cache:false, async:false}

            cursor: 'mfp-ajax-cur', // CSS class that will be added to body during the loading (adds "progress" cursor)
            tError: '<a href="%url%">The content</a> could not be loaded.', //  Error message, can contain %curr% and %total% tags if gallery is enabled
            modal: true
          },
          type: 'ajax',
          callbacks: {
            parseAjax: function(mfpResponse) {
              // mfpResponse.data is a "data" object from ajax "success" callback
              // for simple HTML file, it will be just String
              // You may modify it to change contents of the popup
              // For example, to show just #some-element:
              // mfpResponse.data = $(mfpResponse.data).find('#some-element');

              // mfpResponse.data must be a String or a DOM (jQuery) element

              mfpResponse.data = $(mfpResponse.data).find('#content-popup');

              //console.log('Ajax content loaded:', mfpResponse);
            }
          }
        });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fire

        var feedSessionsHair = new Instafeed({
          get: 'user',
          accessToken: InstagramToken,
          limit: 9,
          target: 'instagram-feed',
          template: '<div class="instafeed-item col alt-col slick-slide-item"><a href="{{link}}" target="_blank" class="instafeed-item-image" style="background-image:url({{image}});"><i class="fab fa-instagram"></i></a></div>',
          after: function(image) {
            
            $('.slider-instafeed').slick({
              infinite: true,
              slidesToShow: 5,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              autoplay: true,
              autoplaySpeed: 0,
              speed: 6000,
              //touchThreshold: 100,
              //edgeFriction: 0,
              //swipeToSlide: true,
              cssEase: 'linear',
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: false,
                    autoplaySpeed: 6000,
                    speed: 400,
                  }
                }
              ]
              });

            }
        });
        feedSessionsHair.run();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
